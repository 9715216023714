import { forwardRef, useImperativeHandle, useState } from "react";
import ColumnContainer from "./ColumnContainer";
import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import TaskCard from "./TaskCard";
import styled from "styled-components";

export type Id = string | number;

export type Column = {
    id: Id;
    title: string;
    color: any
    icon: any;
    value: number
};

export type Task = {
    id: Id;
    columnId: Id;
    content: string;
    details?: any;
    infoAdditional?: any;
    functions?: any;
};

const Container = styled.div`
    display: flex; 
    height: auto;
    width: 100%;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 20px; 

    &::-webkit-scrollbar {
        height: 9px; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(172, 172, 172); 
        border-radius: 6px; 
    }
`;

const Wrapper = styled.div`
    margin: auto;
    display: flex;
`;

const WrapperChild = styled.div`
    display: flex;
    gap: 15px;
`;

const KanbanBoard = forwardRef((props: any, ref: any) => {
    const [tasks, setTasks] = useState<Task[]>([]);

    useImperativeHandle(ref, () => ({
        setTasks
    }));

    const [activeTask, setActiveTask] = useState<Task | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    function deleteTask(id: Id) {
        const newTasks = tasks.filter((task) => task.id !== id);
        setTasks(newTasks);
    }

    function updateTask(id: Id, content: string) {
        const newTasks = tasks.map((task) => {
            if (task.id !== id) return task;
            return { ...task, content };
        });

        setTasks(newTasks);
    }

    function onDragStart(event: DragStartEvent) {
        if (event.active.data.current?.type === "Column") {
            return;
        }

        if (event.active.data.current?.type === "Task") {
            setActiveTask(event.active.data.current.task);
            return;
        }
    }

    function onDragEnd(event: DragEndEvent) {
        setActiveTask(null);

        const { active } = event;

        if (!active) return;

        const item: any = props.status?.find((item: any) => item.id === active.data.current?.task.columnId);
        props.updateStatus(active.id, item.value);
    }

    function onDragOver(event: DragOverEvent) {
        const { active, over } = event;
        if (!over) return;

        const activeId = active.id;
        const overId = over.id;

        if (activeId === overId) return;

        const isActiveATask = active.data.current?.type === "Task";
        const isOverATask = over.data.current?.type === "Task";

        if (!isActiveATask) return;

        if (isActiveATask && isOverATask) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                const overIndex = tasks.findIndex((t) => t.id === overId);

                if (tasks[activeIndex].columnId !== tasks[overIndex].columnId) {
                    tasks[activeIndex].columnId = tasks[overIndex].columnId;
                    return arrayMove(tasks, activeIndex, overIndex - 1);
                }

                return arrayMove(tasks, activeIndex, overIndex);
            });
        }

        const isOverAColumn = over.data.current?.type === "Column";

        if (isActiveATask && isOverAColumn) {
            setTasks((tasks) => {
                const activeIndex = tasks.findIndex((t) => t.id === activeId);
                tasks[activeIndex].columnId = overId;
                return arrayMove(tasks, activeIndex, activeIndex);
            });
        }
    }

    return (
        <Container>
            <DndContext
                sensors={sensors}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragOver={onDragOver}
            >
                <Wrapper>
                    <WrapperChild >
                        <SortableContext items={props.columnsId}>
                            {props.status.map((col: any) => (
                                <ColumnContainer
                                    key={col.id}
                                    column={col}
                                    deleteTask={deleteTask}
                                    updateTask={updateTask}
                                    tasks={tasks.filter((task) => task.columnId === col.id)}
                                />
                            ))}
                        </SortableContext>
                    </WrapperChild>
                </Wrapper>

                {createPortal(
                    <DragOverlay>
                        {activeTask && (
                            <TaskCard
                                task={activeTask}
                                color={'#fff'}
                                deleteTask={deleteTask}
                                updateTask={updateTask}
                            />
                        )}
                    </DragOverlay>,
                    document.body
                )}

            </DndContext>
        </Container>
    );
})

export default KanbanBoard;
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { formatDate } from "../../commons/utils";

const convertJsonToModel = (jsonData: any[], model: any) => {
    return jsonData.map(item => {
        const convertedItem: any = {};
        const alwaysIgnore = ['_versao', 'gruid', 'ususenha'];

        model.inputs && model.inputs.forEach((input: any) => {
            const field = input.id;

            if (item.hasOwnProperty(field) &&
                !convertedItem.hasOwnProperty(input.label) &&
                !model.excelIgnore?.includes(field) &&
                !alwaysIgnore.includes(field)) {

                let value = item[field];

                if (input.type === "date") {
                    value = formatDate(value, true);
                } else if (input.options) {
                    const option = input.options.find((opt: any) => opt.value === value);
                    value = option ? option.label : value;
                }

                convertedItem[input.label] = value;
            }
        });

        model.excel && model.excel.forEach((excelField: any) => {
            if (item.hasOwnProperty(excelField.id) &&
                !model.excelIgnore?.includes(excelField.id) &&
                !alwaysIgnore.includes(excelField.id)) {

                let value = item[excelField.id];

                if (excelField.type === "date") {
                    value = formatDate(value, true);
                }

                convertedItem[excelField.label] = value;
            }
        });

        Object.keys(item).forEach(key => {
            const isNotInInputs = !model.inputs?.some((input: any) => input.id === key);
            const isNotInExcel = !model.excel?.some((excel: any) => excel.id === key);
            const isNotIgnored = !model.excelIgnore?.includes(key);
            const isNotAlwaysIgnored = !alwaysIgnore.includes(key);

            if (isNotInInputs && isNotInExcel && isNotIgnored && isNotAlwaysIgnored) {
                convertedItem[key] = item[key];
            }
        });

        return convertedItem;
    });
};

const autoFitColumns = (ws: XLSX.WorkSheet, jsonData: any[]) => {
    const columnWidths = Object.keys(jsonData[0] || {}).map(key => {
        const maxLength = jsonData.reduce((max, item) =>
            item[key] ? Math.max(max, item[key].toString().length) : max
            , key.length); // Usa o tamanho do cabeçalho como mínimo

        return { wch: maxLength + 2 }; // Adiciona um pequeno espaço extra
    });

    ws['!cols'] = columnWidths;
};

const exportJsonToExcel = (fileName: string, jsonData: any[], model: any): void => {
    const jsonDataConvert = convertJsonToModel(jsonData, model);

    try {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonDataConvert);
        autoFitColumns(worksheet, jsonDataConvert); // Ajuste automático das colunas

        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const excelBuffer: any = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob: Blob = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });

        saveAs(blob, `${fileName}.xlsx`);
    } catch (error) {
        console.error("Erro ao exportar para Excel:", error);
    }
};

export default exportJsonToExcel;

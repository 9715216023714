import { forwardRef, Ref, useImperativeHandle, useRef, useState, useEffect } from "react";
import { Modal } from "../../components/Modal";
import { MdOutlineFileDownload } from "react-icons/md";
import { GalleryContainer, FileContainer, Thumbnail, PdfPlaceholder, Overlay, DownloadButton } from "./styles";
import { ExButton } from "../../components/ExButton";
import { MdNavigateBefore } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa6";
import FilePresentIcon from '@mui/icons-material/FilePresent';

export interface FileItem {
    url: string;
    type: "image" | "document";
}

export interface GalleryHandler {
    openModal: (list: FileItem[], title?: string) => void;
}

export function getFileType(url: string): "image" | "document" {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.svg'];
    const fileExtension = url.slice(url.lastIndexOf('.')).toLowerCase();

    if (imageExtensions.includes(fileExtension)) {
        return 'image';
    }
    return 'document';
}

export const Gallery = forwardRef((props: any, ref: Ref<GalleryHandler>) => {
    const modalRef = useRef<any>();
    const [files, setFiles] = useState<FileItem[]>([]);
    const [selectedFile, setSelectedFile] = useState<FileItem | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [customTitle, setCustomTitle] = useState<string>('');

    const openModal = (fileList: FileItem[], title?: string) => {
        setCustomTitle(title ?? '');
        setSelectedFile(null);
        setSelectedIndex(-1);
        setFiles(fileList);
        modalRef.current?.openModal();
    };

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    const handleOpen = (file: FileItem, index: number) => {
        setSelectedFile(file);
        setSelectedIndex(index);
    };

    const handleClose = () => {
        setSelectedFile(null);
        setSelectedIndex(-1);
    };

    const handleDownload = (url: string) => {
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleNavigation = (direction: "next" | "previous") => {
        if (direction === "next" && selectedIndex < files.length - 1) {
            const nextFile = files[selectedIndex + 1];
            setSelectedFile(nextFile);
            setSelectedIndex(selectedIndex + 1);
        } else if (direction === "previous" && selectedIndex > 0) {
            const prevFile = files[selectedIndex - 1];
            setSelectedFile(prevFile);
            setSelectedIndex(selectedIndex - 1);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft")
                handleNavigation("previous");

            if (event.key === "ArrowRight")
                handleNavigation("next");
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex, files]);

    return (
        <Modal ref={modalRef} title={"Arquivos" + (customTitle ? '- ' + customTitle : '')} size="xl" icon={<FilePresentIcon />}>
            <GalleryContainer>
                {files.map((file, index) => (
                    <FileContainer key={index}>
                        {file.type === "image" ?
                            <Thumbnail src={file.url} alt={file.url} onClick={() => handleOpen(file, index)} /> :
                            <PdfPlaceholder onClick={() => handleOpen(file, index)}>
                                <FaRegFilePdf />
                            </PdfPlaceholder>
                        }
                        <DownloadButton onClick={() => handleDownload(file.url)} iconcenter={<MdOutlineFileDownload />} />
                    </FileContainer>
                ))}
            </GalleryContainer>

            {selectedFile && (
                <Overlay onClick={(e) => { if (e.target === e.currentTarget) handleClose() }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {selectedFile.type === "image" ?
                            <img src={selectedFile.url} alt={selectedFile.url} style={{ maxWidth: "90%", maxHeight: "90%" }} /> :
                            <div style={{ height: '80vh', width: '80vh', overflow: 'hidden' }}>
                                <iframe
                                    src={`https://docs.google.com/viewer?url=${selectedFile.url}&embedded=true`}
                                    width="100%"
                                    height="100%"
                                    title={`Arquivo PDF: ${selectedFile.url}`}
                                />
                            </div>
                        }

                        <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
                            <ExButton onClick={() => handleNavigation("previous")} disabled={selectedIndex === 0}>
                                <MdNavigateBefore style={{ fontSize: '20px', marginRight: '5px' }} />Anterior
                            </ExButton>
                            <ExButton onClick={() => handleNavigation("next")} disabled={selectedIndex === files.length - 1}>
                                Próximo<MdNavigateNext style={{ fontSize: '20px', marginLeft: '5px' }} />
                            </ExButton>
                            <ExButton onClick={() => handleDownload(selectedFile.url)}>
                                <MdOutlineFileDownload style={{ fontSize: '20px', marginRight: '5px' }} />Download
                            </ExButton>
                        </div>
                    </div>
                </Overlay>
            )}
        </Modal>
    );
});

import { useRef } from "react";
import { toastError } from "../../commons/utils";
import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { ExSwitch } from "../../components/ExSwitch";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { model_requisicao_campos } from "../../models";
import { toast } from "react-toastify";

export const RequisicaoCampos = () => {
    const auth = useAuth();

    const refconsulta = useRef<any>();

    async function updateObrigatoriedade(_data: any) {
        await auth.post(model_requisicao_campos.api_post, _data)
            .then(() => {
                toast.success('"Campo Requisição" atualizado com sucesso');
                refconsulta.current.refresh()
            })
            .catch((error: any) => toastError(error))
    }

    const columns = [
        { field: 'reqcamposequencia', header: 'Sequência' },
        { field: 'reqcamponome', header: 'Nome' },
        { field: 'statdescricao', header: 'A partir do status' },
        {
            field: 'reqcampoobrigatorio', header: 'Obrigatório',
            body: (data: any) =>
                <ExSwitch checked={data.reqcampoobrigatorio === 1}
                    onClick={(e: any) => updateObrigatoriedade({ ...data, reqcampoobrigatorio: e.target.checked ? 1 : 0 })}
                />
        },
        { field: 'reqcampoativo', header: 'Ativo' }
    ];

    return (
        <ConsultaPadrao ref={refconsulta} model={model_requisicao_campos} columns={columns} sortField="reqcamposequencia" />
    )
}
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { useReactToPrint } from "react-to-print";
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { formatMonetary, toastError } from '../../commons/utils';
import moment from 'moment';
import { defaultRelatorio } from './defaultRelatorio';

export interface ColaboradorPdfHandler {
    generatePDF: (colid: string) => void
    setFileName: (value: string) => void
}

const ColaboradorPdf = (props: any, ref: Ref<ColaboradorPdfHandler>) => {
    const auth = useAuth();

    const [htmlArq, setHtmlArq] = useState()
    const [fileName, setFileName] = useState<string>()
    const contentRef = useRef<HTMLDivElement>(null)
    const reactToPrintFn = useReactToPrint({ contentRef, ignoreGlobalStyles: true, documentTitle: fileName || 'Ficha do Colaborador' });

    useImperativeHandle(ref, () => ({
        generatePDF,
        setFileName
    }))

    useEffect(() => {
        if (htmlArq) {
            reactToPrintFn()
            setHtmlArq(undefined)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [htmlArq])

    const generatePDF = async (colid: string) => {
        auth.get('/v1/colaboradorpdf', { params: { colid } })
            .then((response: any) => {
                const html = new Function('moment', 'formatFloat', `return \`${defaultRelatorio.replaceAll('@{', '${')}\``).call({
                    ...response.data,
                    usuario: auth,
                    date: moment().format('DD/MM/YYYY HH:mm:ss'),
                }, moment, formatMonetary)

                setHtmlArq(html)
            })
            .catch((error: any) => {
                toastError(error);
            })
    }

    return (
        <div style={{ display: 'none' }} >
            {htmlArq &&
                <div ref={contentRef} >
                    {parse(htmlArq)}
                </div>}
        </div>
    )
}

export default forwardRef(ColaboradorPdf)
import CopyIcon from '@mui/icons-material/ContentCopy';
import { ExButton } from "../../components/ExButton";
import { copyText, toastError } from '../../commons/utils';
import { FileItem, getFileType } from '../Gallery';

export function getNewStatus(status: any, idAtual: any, next: boolean) {
    const statusAtual = status.find((item: any) => item.id === idAtual);

    const candidatos = status.filter((item: any) => item.rejeitado === 0 && (next ? item.sequencia > statusAtual.sequencia : item.sequencia < statusAtual.sequencia));

    const statusAdjacente = candidatos.reduce((adjacente: any, atual: any) =>
        (!adjacente || (next ? atual.sequencia < adjacente.sequencia : atual.sequencia > adjacente.sequencia)) ? atual : adjacente, null
    );

    return statusAdjacente ? statusAdjacente.id : null;
}

export async function updateStatus(auth: any, status: any, data: any, next: boolean) {
    data.colstatusid = getNewStatus(status, data.colstatusid, next);

    await auth.post('/v1/colaboradorstatuscrm', { colstatusid: data.colstatusid }, { colid: data.colid })
        .then(() => {

        })
        .catch((error: any) => {
            console.log(error)
        })
}

export async function confirmDesistencia(auth: any, coldesid: string, colid: string) {
    await auth.post('/v1/colaboradordesistenciaconfirm', { coldesid, colid, coldessituacao: 1, usucodigoaprovacao: auth.usucodigo, coldesdatahoraconfirmacao: new Date() })
        .then(() => {

        })
        .catch((error: any) => {
            toastError(error)
        })
}

export const ButtonCopy = (props: { text: any }) => {
    return (
        (props.text !== null && props.text !== undefined && props.text.toString().trim() !== '') ?
            <ExButton
                size="sm"
                iconcenter={<CopyIcon style={{ fontSize: '16px' }} />}
                color={'transparent'}
                style={{ height: '30px', width: '30px', marginLeft: '6px', display: 'inline-block' }}
                onClick={() => copyText(props.text)}
            /> :
            <ExButton
                size="sm"
                color={'transparent'}
                style={{ height: '30px', width: '30px', marginLeft: '6px', display: 'inline-block' }}
                disabled
            />
    )
}

export async function getFiles(auth: any, colid: string): Promise<FileItem[]> {
    try {
        const response: any = await auth.get('/v1/colaboradorarquivo?colid=' + colid + '&colarqativo=1');

        if (response?.data) {
            const files: FileItem[] = response.data.map((item: any) => ({
                url: item.colarqurl,
                type: getFileType(item.colarqurl)
            }));
            return files;
        } else {
            toastError("Nenhum arquivo encontrado para este colaborador.");
            return [];
        }
    } catch (error: any) {
        toastError(`Erro ao carregar arquivos: ${error.message || error}`);
        return [];
    }
}
import styled from "styled-components";
import { ExButton } from "../../components/ExButton";

export const GalleryContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  user-select: none;
`;

export const FileContainer = styled.div`
  text-align: center;
  position: relative; 
`;

export const Thumbnail = styled.img`
  max-height: 250px;
  max-width: 250px;
  cursor: pointer;
`;

export const PdfPlaceholder = styled.div`
  width: 175px;
  height: 250px;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7a7a7a;
  cursor: pointer;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;
`;

export const DownloadButton = styled(ExButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
`;

import styled from "styled-components";
import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_colaborador, model_colaborador_status, options_cor_etinia, options_deficiente, options_escolaridade, options_estadocivil, options_sim_nao } from "../../models";
import { Col, Row } from "react-bootstrap";
import { ageCalculator, formatDataCompare, formatDate, formatMonetary, getLocalStorage, setLocalStorage, toastCustomError, toastError } from "../../commons/utils";
import { ColaboradorInvalidacoes } from "../ColaboradorInvalidacoes";
import { useEffect, useRef, useState } from "react";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { LabelDataTable } from "../../components/LabelDataTable";
import { ButtonWhatsApp } from "../../components/ButtonWhatsApp";
import ColaboradorPdf, { ColaboradorPdfHandler } from "../ColaboradorPdf";
import CloseIcon from '@mui/icons-material/Close';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import PrintIcon from '@mui/icons-material/Print';
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { ButtonCopy, confirmDesistencia, getFiles, updateStatus } from "./utils";
import { Gallery, GalleryHandler } from "../Gallery";
import FilePresentIcon from '@mui/icons-material/FilePresent';

const ContainerDetail = styled.div`
    font-size: 12px;
    padding: 0 1rem;
`
const LabelDetail = styled.div`
    background-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
    margin: 5px 1rem 8px 0px;
    padding: 4px 10px;
`
const RowDetail = styled.div`
    margin-top: 2px;
    padding-left: 5px;
    margin-bottom: 2px;
    user-select: none;
`;

const DivCaption = styled.div`
    display: inline-block;
    margin-right: 4px;
`

const DivValue = styled.div`
    display: inline-block;
    user-select: text;
`

export const details = (row: any) => {
    function ViewDados(label: string, value: any) {
        return (
            <RowDetail>
                <DivCaption>{label}</DivCaption>
                <DivValue>{value} </DivValue>
                <ButtonCopy text={value} />
            </RowDetail>
        )
    }

    function getLabelByValue(options: any, value: any) {
        const option = options.find((option: any) => option.value === value);
        return option ? option.label : "";
    }

    return (
        <ContainerDetail>
            <Row>
                <Col className={"p-0"}>
                    <LabelDetail>DADOS PESSOAIS</LabelDetail>
                    {ViewDados('Orgão Expedidor RG:', row.colrgorgaoexpedicao)}
                    {ViewDados('Data Expedição RG:', formatDate(row.colrgdataexpedicao))}
                    {ViewDados('Município de Nascimento:', row.munnomenascimento)}
                    {ViewDados('UF de Nascimento:', row.munufnascimento)}
                    {ViewDados('Nascimento:', `${formatDate(row.colnascimento)} (${ageCalculator(row.colnascimento)} anos)`)}
                    {ViewDados('Sexo:', row.colsexo === 0 ? 'Masculino' : row.colsexo === 1 ? 'Feminino' : 'Outro')}
                    {ViewDados('E-mail:', row.colemail)}
                    {ViewDados('Dependentes:', row.coldependentes)}
                    {ViewDados('Cor/Etnia:', getLabelByValue(options_cor_etinia, row.colcoretinia))}
                    {ViewDados('Estado Civil:', getLabelByValue(options_estadocivil, row.colestadocivil))}
                    {ViewDados('Escolaridade:', getLabelByValue(options_escolaridade, row.colescolaridade))}
                    {ViewDados('Deficiência:', getLabelByValue(options_deficiente, row.coldeficiencia))}
                    {ViewDados('Outra Deficiência:', row.coloutradeficiencia)}
                    {ViewDados('Pai:', row.colnomepai)}
                    {ViewDados('Mãe:', row.colnomemae)}
                    {ViewDados('Cônjuge:', row.colnomeconjuge)}
                </Col>
                <Col className={"p-0"}>
                    <LabelDetail>ENDEREÇO</LabelDetail>
                    {ViewDados('CEP:', row.colcep)}
                    {ViewDados(
                        'Logradouro: ',
                        (row.collogradouro ?? '') + (row.collogradouronumero ? ' Nº ' + row.collogradouronumero : '')
                    )}
                    {ViewDados('Bairro:', row.colbairro)}
                    {ViewDados('Município:', row.munnome)}
                    {ViewDados('UF:', row.munuf)}
                </Col>
                <Col className={"p-0"}>
                    <LabelDetail>DADOS PROFISSIONAIS</LabelDetail>
                    {ViewDados('Salário Inicial:', formatMonetary(row.colsalarioinicial))}
                    {ViewDados('Sindicalizado:', getLabelByValue(options_sim_nao, row.colsindicalizado))}
                    {ViewDados('Contribuição Sindical:', getLabelByValue(options_sim_nao, row.colcontribuicaosindical))}
                    {ViewDados('Jornada de Trabalho:', row.jorntrabdescricao)}
                    {ViewDados('Conta Bancária:', (row.colcbagencia ? row.colcbagencia : '') + (row.colcbconta ? ' - ' + row.colcbconta : ''))}
                </Col>
            </Row>
        </ContainerDetail>
    );
};

export const Colaborador = () => {
    const auth = useAuth();

    const refinvalidacoes = useRef<any>();
    const refdatatable = useRef<any>();
    const iadmissaoref = useRef<any>();
    const fadmissaoref = useRef<any>();
    const statusref = useRef<any>();
    const refColaboradorPdf = useRef<ColaboradorPdfHandler>(null);
    const refgallery = useRef<GalleryHandler>(null);

    const [showing, setShowing] = useState(false);
    const [apiFilter, setApiFilter] = useState('');

    const [status, setStatus] = useState<any>();

    useEffect(() => {
        auth.get('/v1/colaboradorstatus')
            .then((response: any) => {
                const filteredData = response.data.filter((item: any) => item.colstatusativo === 1).sort((a: any, b: any) => a.colstatussequencia - b.colstatussequencia);

                const _status = filteredData.map((item: any) => ({
                    id: item.colstatusid,
                    title: item.colstatusdescricao,
                    color: item.colstatuscor,
                    value: item.colstatusid,
                    sequencia: item.colstatussequencia,
                    rejeitado: item.colstatusrejeicao
                }));

                setStatus(_status);
            })
            .catch((error: any) => {
                toastError(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const FormatTelefoneWhatsApp = (num: string) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{num}</span>
                {num && <ButtonWhatsApp num={num} />}
            </div>
        );
    };

    const columns = [
        { field: 'colnome', header: 'Nome' },
        { field: 'grudescricao', header: 'Grupo' },
        { field: 'colcpf', header: 'CPF' },
        { field: 'colrg', header: 'RG' },
        { field: 'coldatacadastro', header: 'Cadastro', body: (e: any) => formatDate(e.coldatacadastro, true) },
        { field: 'coladmissao', header: 'Admissão', body: (e: any) => formatDate(e.coladmissao, true) },
        { field: 'cbodescricao', header: 'CBO (Cargo)' },
        { field: 'coltelefone', header: 'Telefone', body: (e: any) => FormatTelefoneWhatsApp(e.coltelefone) },
        { field: 'colstatusid', header: 'Status', body: (e: any) => { return LabelDataTable(e.colstatusdescricao, e.colstatuscor || '#e4e6e8') } },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    function getFunctions(row: any) {
        const sequencias = status?.map((item: any) => item.sequencia) || [];

        const menorSequencia = Math.min(...sequencias);
        const maiorSequencia = Math.max(...sequencias);

        return row.coldesid ? [{ click: () => confirmDesistencia(auth, row.coldesid, row.colid).then(onSuccess), icon: <CloseIcon />, color: 'red', label: 'Desistente' }] :
            [
                { click: () => refinvalidacoes.current.openModal(row), icon: <PlaylistRemoveIcon />, color: '#da5a33' },
                { click: () => updateStatus(auth, status, row, false).then(onSuccess), icon: <UndoIcon />, disabled: row.colstatussequencia === menorSequencia || row.colstatusrejeicao === 1 },
                { click: () => updateStatus(auth, status, row, true).then(onSuccess), icon: <RedoIcon />, disabled: row.colstatussequencia === maiorSequencia || row.colstatusrejeicao === 1 },
                { click: () => refColaboradorPdf.current?.generatePDF(row.colid), icon: <PrintIcon /> },
                {
                    click: async () => {
                        const files = await getFiles(auth, row.colid);
                        if (files.length === 0)
                            toastCustomError('Colaborador não possui nenhum arquivo associado.')
                        else
                            refgallery.current?.openModal(files, row.colnome);
                    },
                    icon: <FilePresentIcon />
                }
            ]
    }

    function onSuccess() {
        refdatatable.current.refresh();
    }

    function filterData(data: any, iAdmissao: any, fAdmissao: any, status: any) {
        let _data: any = [];

        _data = data;

        if (iAdmissao)
            _data = _data.filter((item: any) => formatDataCompare(item.coladmissao) >= iAdmissao);

        if (fAdmissao)
            _data = _data.filter((item: any) => formatDataCompare(item.coladmissao) <= fAdmissao);

        if (status && (status.length > 0)) {
            const _status = new Set(status.map((item: any) => item.label));

            _data = _data.filter((item: any) => _status.has(item.colstatusdescricao));
        }

        setLocalStorage('i_admissao_colaboradores', iAdmissao ?? null);
        setLocalStorage('f_admissao_colaboradores', fAdmissao ?? null);
        setLocalStorage('status_colaboradores', status ?? null);

        refdatatable.current.setCustomData(_data);
    }

    async function changeAdmissaoInicial(value: any, data: any) {
        filterData(data, value, fadmissaoref.current.getValue(), statusref.current.getValue())
    }

    async function changeAdmissaoFinal(value: any, data: any) {
        filterData(data, iadmissaoref.current.getValue(), value, statusref.current.getValue())
    }

    async function changeStatus(selecteds: any, data: any) {
        filterData(data, iadmissaoref.current.getValue(), fadmissaoref.current.getValue(), selecteds)
    }

    function afterRefresDataTable(data: any) {
        var iadmissao;
        var fadmissao;
        var status;

        if (!showing) {
            iadmissao = getLocalStorage('i_admissao_colaboradores');
            fadmissao = getLocalStorage('f_admissao_colaboradores');
            status = getLocalStorage('status_colaboradores');

            filterData(data, iadmissao, fadmissao, status);

            setShowing(true);
        } else
            filterData(data, iadmissaoref.current.getValue(), fadmissaoref.current.getValue(), statusref.current.getValue())
    }

    const filters = [
        {
            ref: iadmissaoref, md: 2, id: 'iAdmissao', label: 'Admissão Inicial', type: 'date',
            onChange: changeAdmissaoInicial, defaultValue: getLocalStorage('i_admissao_colaboradores')
        },
        {
            ref: fadmissaoref, md: 2, id: 'fAdmissao', label: 'Admissão Final', type: 'date',
            onChange: changeAdmissaoFinal, defaultValue: getLocalStorage('f_admissao_colaboradores')
        },
        {
            ref: statusref, md: 5, id: 'status', label: 'Status', type: 'select', model: model_colaborador_status, isMulti: true,
            onChange: changeStatus, defaultValue: getLocalStorage('status_colaboradores')
        },
        {
            md: 3, id: 'viewallgroups', label: 'Visualizar todas as Empresas', type: 'check', customStyle: { marginTop: '29px' },
            onClick: (e: any) => {
                setApiFilter('?allgroups=' + (e.target.checked ? '1' : '0'));
                refdatatable.current.refresh('?allgroups=' + (e.target.checked ? '1' : '0'));
            }
        }
    ]

    return (
        <>
            <ColaboradorPdf ref={refColaboradorPdf} />
            <ColaboradorInvalidacoes
                ref={refinvalidacoes}
                onSuccess={onSuccess}
            />
            <ConsultaPadrao
                ref={refdatatable}
                model={model_colaborador}
                columns={columns}
                details={details}
                functions={getFunctions} sortField='coldatacadastro' sortOrder={-1}
                inputfilters={filters}
                onAfterRefresh={afterRefresDataTable}
                apifilter={apiFilter}
            />
            <Gallery ref={refgallery} />
        </>
    )
} 
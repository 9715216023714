import axios from "axios";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { ExTooltip } from "../components/Tooltip";
import { ExButton } from "../components/ExButton";
import { differenceInYears } from 'date-fns';
import tinycolor from "tinycolor2";

export function navigate(to: any) {
    window.history.pushState({}, '', to);
    window.dispatchEvent(new PopStateEvent('popstate'));
}

export function isMobileDevice() {
    return window.matchMedia('(max-width: 767px)').matches;
};

export function onValidate(elements: any) {
    let validated = true;

    if (elements)
        Object.values(elements.current).forEach((input: any) => {
            if ((validated) && (!input.validate())) {
                validated = false
            }
        });

    return validated;
}

export function cpfIsValid(cpf: any) {
    var soma = 0;
    var resto;

    var cpfStr = cpf.replace(/[^\d]/g, '')

    if (cpfStr.length !== 11)
        return false;

    if ([
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ].indexOf(cpfStr) !== -1)
        return false;

    for (let i = 1; i <= 9; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (11 - i);

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(9, 10)))
        return false

    soma = 0

    for (let i = 1; i <= 10; i++)
        soma = soma + parseInt(cpfStr.substring(i - 1, i)) * (12 - i)

    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11))
        resto = 0

    if (resto !== parseInt(cpfStr.substring(10, 11)))
        return false

    return true
}

export function cnpjIsValid(cnpj: string): boolean {
    const cleanedCnpj = cnpj.replace(/[^\d]+/g, '');

    if (cleanedCnpj === '' || cleanedCnpj.length !== 14) return false;

    const invalidCnpjs = [
        "00000000000000", "11111111111111", "22222222222222", "33333333333333",
        "44444444444444", "55555555555555", "66666666666666", "77777777777777",
        "88888888888888", "99999999999999"
    ];
    if (invalidCnpjs.includes(cleanedCnpj)) return false;

    const calculateVerifierDigit = (base: string, length: number): number => {
        let soma = 0;
        let pos = length - 7;

        for (let i = length; i >= 1; i--) {
            soma += parseInt(base.charAt(length - i)) * pos--;
            if (pos < 2) pos = 9;
        }

        return soma % 11 < 2 ? 0 : 11 - soma % 11;
    };

    const baseCnpj = cleanedCnpj.substring(0, 12);
    const firstVerifierDigit = calculateVerifierDigit(baseCnpj, 12);

    if (firstVerifierDigit !== parseInt(cleanedCnpj.charAt(12))) return false;

    const secondVerifierDigit = calculateVerifierDigit(cleanedCnpj.substring(0, 13), 13);

    return secondVerifierDigit === parseInt(cleanedCnpj.charAt(13));
}

export function emailIsValid(email: any) {
    var user = email.substring(0, email.indexOf("@"));
    var dominio = email.substring(email.indexOf("@") + 1, email.length);

    if ((user.length >= 1) &&
        (dominio.length >= 3) &&
        (user.search("@") === -1) &&
        (dominio.search("@") === -1) &&
        (user.search(" ") === -1) &&
        (dominio.search(" ") === -1) &&
        (dominio.search(".") !== -1) &&
        (dominio.indexOf(".") >= 1) &&
        (dominio.lastIndexOf(".") < dominio.length - 1))
        return true
    else
        return false
}

export function copyText(value: any) {
    copy(value);
    toast.success('Copiado com sucesso.')
}

export const formatMonetary = (valor: Number) => {
    if ((!valor) || (String(valor) === ''))
        valor = 0;

    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export const formatDecimal = (valor: Number) => {
    if ((!valor) || (String(valor) === ''))
        valor = 0;

    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }).replace('R$', '').trim();
}

export const formatDate = (value: any, returnNull?: any) => {
    if (!value)
        if (returnNull)
            return ''
        else
            value = new Date();

    else if (typeof value === 'string')
        value = new Date(value);

    return value.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const formatDateTime = (value: any, returnNull?: any) => {
    if (!value)
        if (returnNull)
            return ''
        else
            value = new Date();

    else if (typeof value === 'string')
        value = new Date(value);

    return value.toLocaleString('pt-BR');
};

export const formatDateTimeFile = (value: any) => {
    value = formatDateTime(value);
    return value.toLocaleString('pt-BR').replace(/[/:,]/g, '');
};

export function arraysEqual(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
}

export function formatPercentColumn(value: number) {
    if (value < 0) {
        return <span style={{ color: '#ff6363' }}>{formatDecimal(value)}%</span>;
    } else {
        return formatDecimal(value) + '%';
    }
};

export function toastError(error: any) {
    console.log(error);
    toast.error(error?.response?.data?.error ? error?.response?.data?.error : 'Erro ao concluir a operação.');
}

export function toastCustomError(error: any) {
    console.log(error);
    toast.error(error, error);
}


export async function getApiCEP(cep: string): Promise<any> {
    try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        return response.data;
    } catch (error) {
        console.error('Erro ao consultar o CEP:', error);
        throw error;
    }
}

export async function getApiCNPJ(cnpj: string): Promise<any> {
    let _cnpj = cnpj.replace(/\D/g, '');

    const consultarCNPJ = require("consultar-cnpj");

    try {
        const empresa = await consultarCNPJ(_cnpj);
        return empresa;
    } catch (error) {
        console.log(error);
    }
}

export function camelize(text: string) {
    if (!text)
        return;

    let _text = text.toLowerCase();
    return _text.replace(/\b[a-z]/g, m => m.toUpperCase());
}

export function convertImageToBase64(url: any) {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.crossOrigin = 'Anonymous';
        image.src = url;

        image.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            const ctx: any = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0);
            const base64 = canvas.toDataURL('image/png');
            resolve(base64);
        };

        image.onerror = reject;
    });
}

export function getIconCultura(descricao: string) {
    const _descUpper = descricao?.toUpperCase();
    if (_descUpper === 'CENOURA')
        return "https://cdn-icons-png.flaticon.com/512/5124/5124099.png"
    else if (_descUpper === 'ALHO')
        return "https://cdn-icons-png.flaticon.com/512/6409/6409621.png";
}

export function formatDataCompare(date: any) {
    if (!date)
        return ''

    const d = new Date(date);
    return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
}

export function setLocalStorage(key: any, e: any) {
    localStorage.setItem(key, JSON.stringify(e));
}

export function getLocalStorage(key: any) {
    const json = localStorage.getItem(key)

    if ((!json) || (json === undefined)) {
        return null;
    }

    const result = JSON.parse(json)

    return result ?? null;
}

export const ButtonCard = (props: any) => {
    return (
        <ExTooltip title={props.hint}>
            <ExButton
                {...props}
                size='sm'
                style={{ ...props.style, color: tinycolor(props.color).isDark() ? '#EAF4FD' : '#383838', borderRadius: '50%' }}
                className='p-1 me-1'
            />
        </ExTooltip>
    )
}

export function ageCalculator(_data: any) {
    const today = new Date();
    const age = differenceInYears(today, _data);
    return age || 0;
}
import { Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { formatDataCompare, formatMonetary, isMobileDevice, toastError } from "../../../commons/utils";
import BarChart from "../../../charts/Bar";
import { Input } from "../../../components/Input";
import PieChart from "../../../charts/pie";
import moment from "moment";
import { GridCard } from "..";
import { ExCard } from "../../../charts/Card";
import { FaSackDollar } from "react-icons/fa6";
import { FaCheckDouble } from "react-icons/fa6";
import { FaRegQuestionCircle } from "react-icons/fa";
import { ExSelect } from "../../../components/ExSelect";
import { model_imobilizado, model_imobilizado_categoria, model_imobilizado_subcategoria } from "../../../models";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import AreaChart from "../../../charts/area";

const DateInput = forwardRef(({ id, label, onChange, defaultValue }: any, ref) => (
    <Input ref={ref} id={id} type="date" label={label} onAfterChange={onChange} defaultValue={defaultValue} />
));

export const DashRequisicoes = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const originData = useRef<any[]>([]);

    const refemissaoi = useRef<any>();
    const refemissaof = useRef<any>();
    const refCategoria = useRef<any>()
    const refSubcategoria = useRef<any>()
    const refImobilizado = useRef<any>()

    const refchartimobilizado = useRef<any>();
    const refchartcatimobilizado = useRef<any>();
    const refchartsubcatimobilizado = useRef<any>();
    const refchartfornecedores = useRef<any>();
    const refchartgrupos = useRef<any>()
    const refchartmensal = useRef<any>();
    const refcardqtde = useRef<any>();
    const refcardtotal = useRef<any>();
    const refcardsemcat = useRef<any>();
    const refFilter = useRef<any>({})

    useImperativeHandle(ref, () => ({
        setData
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseC: any = await auth.get(model_imobilizado_categoria.api_get, {limit: 10000});
                const responseS: any = await auth.get(model_imobilizado_subcategoria.api_get, {limit: 10000});
                const responseI: any = await auth.get(model_imobilizado.api_get, {limit: 10000});

                const formattedDataC = responseC.data.map((item: any) => ({ value: item.imobcatid, label: item.imobcatdescricao }));
                const formattedDataS = responseS.data.map((item: any) => ({ value: item.imobsubcatid, label: item.imobsubcatdescricao }));
                const formattedDataI = responseI.data.map((item: any) => ({ value: item.imobid, label: item.imobdescricao }));

                await refCategoria.current.setCustomOptions(formattedDataC);
                await refSubcategoria.current.setCustomOptions(formattedDataS);
                await refImobilizado.current.setCustomOptions(formattedDataI);
            } catch (error) {
                toastError(error);
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNextMultipleOf1000 = (value: number) => Math.ceil(value / 1000) * 1000;

    const getValue = (groupByKey: string | ((item: any) => string), item: any) => typeof groupByKey === 'function' ? groupByKey(item) : item[groupByKey] || 'Não Informado'

    const groupData = (data: any[], groupByKey: string | ((item: any) => string)) =>
        data.reduce((acc: Record<string, number>, item: any) => {
            const key = getValue(groupByKey, item);
            const value = parseFloat((item.total || 0).toFixed(2));
            acc[key] = (acc[key] || 0) + value;
            return acc;
        }, {});

    const populateChart = (refChart: any, data: any, options: { groupByKey: any; chartTitle: string; limit?: number; type?: 'Bar' | 'Pie'; labelFontSize?: string }) => {
        const { groupByKey, chartTitle, limit = Infinity, type = 'Bar', labelFontSize = '16px' } = options;

        var fdataPointSelection

        if (groupByKey) {
            fdataPointSelection = (e: any, ct: any, { w }: any) => {
                const acceptValues = w.globals.selectedDataPoints[0]?.map((i: number) => w.globals.labels[i]).filter((e: any) => e)

                if (!acceptValues || acceptValues.length === 0)
                    delete refFilter.current[groupByKey]
                else
                    refFilter.current[groupByKey] = (e: any) => acceptValues.includes(getValue(groupByKey, e))

                prePopulate(refemissaoi.current.getValue(), refemissaof.current.getValue())
            }
        }

        const filterArr = Object.entries(refFilter.current)
        data = [...data].filter((e: any) => filterArr.every(([key, val]: any) => key === groupByKey || val(e)))

        const groupedData = groupData(data, groupByKey);

        let entries: [string, number][];

        if (typeof groupByKey === 'function')
            entries = Object.entries(groupedData).sort(([keyA], [keyB]) => {
                const dateA = moment(keyA, 'MM/YYYY');
                const dateB = moment(keyB, 'MM/YYYY');
                return dateA.diff(dateB);
            }).slice(0, limit);
        else
            entries = Object.entries(groupedData).sort(([, a], [, b]) => b - a).slice(0, limit);

        const labels = entries.map(([key]) => key);
        const values = entries.map(([, value]) => value);

        if (type === 'Bar') {
            refChart?.current.setOptions(labels, getNextMultipleOf1000(Math.max(...values)), chartTitle, undefined, undefined, undefined, labelFontSize, fdataPointSelection);
            refChart?.current.setSeries([{ name: chartTitle, data: values }]);
        } else if (type === 'Pie') {
            refChart?.current.setLabels(labels);
            refChart?.current.setOptions(fdataPointSelection);
            refChart?.current.setSeries(values);
        }
    };

    const setCardValues = (ref: any, value: any) => {
        ref?.current.setValue(value);
    };

    const filterByDate = (data: any[], startDate?: any, endDate?: any) =>
        data.filter((item: any) => {
            const date = formatDataCompare(item.reqdata);
            return (!startDate || date >= startDate) && (!endDate || date <= endDate);
        });

    const prePopulate = (startDate?: any, endDate?: any, filters?: { categoria?: any, subcategoria?: any, imobilizado?: any }) => {
        var filteredData = filterByDate(originData.current, startDate, endDate);

        const categorias = filters?.categoria || refCategoria.current.getValue()

        if (categorias && categorias.length > 0) {
            const _categorias = new Set(categorias.map((item: any) => item.value));
            filteredData = filteredData.filter((item: any) => _categorias.has(item.imobcatid));
        }

        const subcategorias = filters?.subcategoria || refSubcategoria.current.getValue()

        if (subcategorias && subcategorias.length > 0) {
            const _subcategorias = new Set(subcategorias.map((item: any) => item.value));
            filteredData = filteredData.filter((item: any) => _subcategorias.has(item.imobsubcatid));
        }

        const imobilizados = filters?.imobilizado || refImobilizado.current.getValue()

        if (imobilizados && imobilizados.length > 0) {
            const _imobilizados = new Set(imobilizados.map((item: any) => item.value));
            filteredData = filteredData.filter((item: any) => _imobilizados.has(item.imobid));
        }

        const filterArr = Object.entries(refFilter.current)
        const cardData = filteredData.filter((e: any) => filterArr.every(([key, val]: any) => val(e)))

        setCardValues(refcardqtde, cardData.length || 0);
        setCardValues(refcardtotal, formatMonetary(cardData.reduce((sum: any, item: any) => sum + item.total, 0)));
        setCardValues(refcardsemcat, formatMonetary(
            cardData.filter((item: any) => !item.imobcatdescricao).reduce((sum: any, item: any) => sum + item.total, 0)
        ));

        populateChart(refchartmensal, filteredData, {
            groupByKey: (item: any) => moment(item.reqdata).format('MM/YYYY'),
            chartTitle: 'Valor por Mês',
        });

        populateChart(refchartimobilizado, filteredData, {
            groupByKey: 'imobdescricao',
            chartTitle: 'Top 15 - Valor por Imobilizado',
            limit: 15,
        });

        populateChart(refchartfornecedores, filteredData, {
            groupByKey: 'fornome',
            chartTitle: 'Top 5 - Fornecedores',
            limit: 5,
            type: 'Pie',
        });

        populateChart(refchartgrupos, filteredData, {
            groupByKey: 'grudescricao',
            chartTitle: 'Grupos',
            type: 'Pie',
        });

        populateChart(refchartcatimobilizado, filteredData.filter((item: any) => item.imobcatdescricao), {
            groupByKey: 'imobcatdescricao',
            chartTitle: 'Valor por Categoria de Imobilizado',
        });

        populateChart(refchartsubcatimobilizado, filteredData.filter((item: any) => item.imobsubcatdescricao), {
            groupByKey: 'imobsubcatdescricao',
            limit: 10,
            chartTitle: 'Top 10 - Valor por Subcategoria de Imobilizado',
        });
    };

    const setData = (_data: any) => {
        originData.current = _data;
        prePopulate(refemissaoi.current.getValue(), refemissaof.current.getValue());
    };

    async function changeFilterCat(selected: any, data: any) {
        prePopulate(refemissaoi.current.getValue(), refemissaof.current.getValue(), { categoria: selected })
    }

    async function changeFilterSubcat(selected: any, data: any) {
        prePopulate(refemissaoi.current.getValue(), refemissaof.current.getValue(), { subcategoria: selected })
    }

    async function changeFilterImob(selected: any, data: any) {
        prePopulate(refemissaoi.current.getValue(), refemissaof.current.getValue(), { imobilizado: selected })
    }

    return (
        <Grid container spacing={2} sx={{ padding: isMobileDevice() ? '1rem' : '1rem 8rem' }}>
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={isMobileDevice() ? 12 : 1}>
                        <DateInput ref={refemissaoi} id="dash_req_emis_inicial" label="Emissão Inicial" onChange={(value: any) => prePopulate(value, refemissaof.current.getValue())} />
                    </Grid>
                    <Grid item xs={isMobileDevice() ? 12 : 1}>
                        <DateInput ref={refemissaof} id="dash_req_emis_final" label="Emissão Final" onChange={(value: any) => prePopulate(refemissaoi.current.getValue(), value)} />
                    </Grid>
                    <Grid item xs={isMobileDevice() ? 12 : 3}>
                        <ExSelect
                            ref={refCategoria}
                            id='categoria'
                            label='Categoria'
                            model={model_imobilizado_categoria}
                            onAfterChange={changeFilterCat}
                            isMulti
                        />
                    </Grid>
                    <Grid item xs={isMobileDevice() ? 12 : 3}>
                        <ExSelect
                            ref={refSubcategoria}
                            id='subcategoria'
                            label='Subcategoria'
                            model={model_imobilizado_subcategoria}
                            onAfterChange={changeFilterSubcat}
                            isMulti
                        />
                    </Grid>
                    <Grid item xs={isMobileDevice() ? 12 : 3}>
                        <ExSelect
                            ref={refImobilizado}
                            id='imobilizado'
                            label='Imobilizado'
                            model={model_imobilizado}
                            onAfterChange={changeFilterImob}
                            isMulti
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 8}>
                <BarChart ref={refchartcatimobilizado} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 4}>
                <div style={{ height: '450px' }}>
                    <PieChart ref={refchartgrupos} title='Grupos' monetary />
                </div>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 8}>
                <BarChart ref={refchartsubcatimobilizado} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 4}>
                <div style={{ height: '450px' }}>
                    <PieChart ref={refchartfornecedores} title='Top 5 - Fornecedores' monetary />
                </div>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 12}>
                <BarChart ref={refchartimobilizado} />
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 12}>
                <Grid container spacing={2}>
                    <GridCard md={4}>
                        <ExCard ref={refcardqtde} title='Quantidade Total' icon={<FaCheckDouble />} />
                    </GridCard>
                    <GridCard md={4}>
                        <ExCard ref={refcardtotal} title='Valor Total' icon={<FaSackDollar />} />
                    </GridCard>
                    <GridCard md={4}>
                        <ExCard ref={refcardsemcat} title='Total sem Categoria' icon={<FaRegQuestionCircle />} />
                    </GridCard>
                </Grid>
            </Grid>
            <Grid item xs={isMobileDevice() ? 12 : 12}>
                <AreaChart ref={refchartmensal} />
            </Grid>
        </Grid>
    );
});

import { useEffect, useRef, useState } from "react"
import KanbanBoard from "../../components/Kanban/Kanban";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { ButtonCard, toastCustomError, toastError } from "../../commons/utils";
import { Filtro } from "./Filtro";
import { ColaboradorInvalidacoes } from "../ColaboradorInvalidacoes";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import CloseIcon from '@mui/icons-material/Close';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import PrintIcon from '@mui/icons-material/Print';
import CreateIcon from '@mui/icons-material/Create';
import { ExCadastroPadrao } from "../../components/ExCadastroPadrao";
import { model_colaborador } from "../../models";
import ColaboradorPdf, { ColaboradorPdfHandler } from "../ColaboradorPdf";
import { confirmDesistencia, getFiles, updateStatus } from "../Colaborador/utils";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Gallery, GalleryHandler } from "../Gallery";

function createButton(hint: string, color: string, icon: JSX.Element, onClick: () => void) {
    return (
        <ButtonCard hint={hint} color={color} iconcenter={icon} onClick={onClick} className='p-1 me-1' />
    );
}

export const ColaboradorKanban = () => {
    const auth = useAuth();

    const refKanbam = useRef<any>();
    const refCadastroPadrao = useRef<any>();
    const refFiltro = useRef<any>();
    const refinvalidacoes = useRef<any>();
    const refColaboradorPdf = useRef<ColaboradorPdfHandler>(null);
    const refgallery = useRef<GalleryHandler>(null);

    const [status, setStatus] = useState<any>();

    useEffect(() => {
        auth.get('/v1/colaboradorstatus')
            .then((response: any) => {
                const filteredData = response.data.filter((item: any) => item.colstatusativo === 1).sort((a: any, b: any) => a.colstatussequencia - b.colstatussequencia);

                const _status = filteredData.map((item: any) => ({
                    id: item.colstatusid,
                    title: item.colstatusdescricao,
                    color: item.colstatuscor,
                    value: item.colstatusid,
                    sequencia: item.colstatussequencia,
                    rejeitado: item.colstatusrejeicao
                }));

                _status.splice(-1, 0, {
                    id: 'desistente',
                    title: 'Desistente',
                    color: 'red',
                    value: 'desistente',
                    sequencia: _status[_status.length - 1].colstatussequencia - 0.5
                })

                setStatus(_status);
            })
            .catch((error: any) => {
                toastError(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onGetData(data: any) {
        const newArray: any = [];
        refKanbam.current.setTasks([]);

        const sequencias = status.map((item: any) => item.sequencia);

        const menorSequencia = Math.min(...sequencias);
        const maiorSequencia = Math.max(...sequencias);

        data.forEach((item: any) => {

            const { colid, grudescricao, colnome, colcpf, colstatusid, colstatussequencia, colstatusrejeicao, coldesid } = item;

            const id = colid;
            const content =
                <div style={{ paddingTop: '5px' }}>
                    {colnome && <div style={{ paddingBottom: '5px' }}>{colnome}</div>}
                    {colcpf && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{colcpf}</div>}
                    {coldesid && <div style={{ fontSize: '12px', padding: '4px', borderRadius: '4px', color: 'white', backgroundColor: 'red', display: 'inline-block' }}>    Desistente    </div>}
                </div>
            const columnId = coldesid ? 'desistente' : colstatusid;
            const details = grudescricao;
            const color = status.find((_status: any) => _status.value === colstatusid)?.color;

            const functions = (
                coldesid ?
                    createButton('Confirmar Desistência', 'red', <CloseIcon />, () => confirmDesistencia(auth, coldesid, colid).then(e => refFiltro.current.refresh()))
                    :
                    <>
                        {createButton('Invalidar', color, <PlaylistRemoveIcon />, () => refinvalidacoes.current.openModal(item))}
                        {createButton('Editar', color, <CreateIcon />, () => refCadastroPadrao.current.openModal(item))}
                        {colstatusrejeicao !== 1 && (
                            <>
                                {colstatussequencia !== menorSequencia &&
                                    createButton('Retroceder Status', color, <UndoIcon />, () => updateStatus(auth, status, item, false).then(e => refFiltro.current.refresh()))}

                                {colstatussequencia !== maiorSequencia &&
                                    createButton('Avançar Status', color, <RedoIcon />, () => updateStatus(auth, status, item, true).then(e => refFiltro.current.refresh()))}
                            </>
                        )
                        }
                        {createButton('Visualizar Arquivos', color, <FilePresentIcon />,
                            async () => {
                                const files = await getFiles(auth, colid);
                                if (files.length === 0)
                                    toastCustomError('Colaborador não possui nenhum arquivo associado.')
                                else
                                    refgallery.current?.openModal(files, colnome);
                            }
                        )}
                        {createButton('Imprimir', color, < PrintIcon />, () => refColaboradorPdf.current?.generatePDF(colid))}
                    </>
            );

            newArray.push({ id, content, columnId, details, undefined, functions });
        });

        refKanbam.current.setTasks(newArray);
    }

    function onSuccess() {
        refFiltro.current.refresh();
    }

    return (
        status &&
        <>
            <ColaboradorPdf ref={refColaboradorPdf} />
            <ExCadastroPadrao ref={refCadastroPadrao} model={model_colaborador} success={onSuccess} />
            <ColaboradorInvalidacoes ref={refinvalidacoes} onSuccess={onSuccess} />
            <Filtro ref={refFiltro} onGetData={onGetData} />
            <KanbanBoard ref={refKanbam} columnsId={status?.map((item: any) => item.id)} status={status} />
            <Gallery ref={refgallery} />
        </>
    )
}
import { useRef } from "react";
import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_produto_unidademedida } from "../../models";
import { ProdutoUnidadeMedidaProduto, ProdutoUnidMedProdRef } from "./ProdutoUnidadeMedidaProduto";
import { COLOR_PRIMARY } from "../../theme/styles";
import { FaBoxOpen } from "react-icons/fa";

export const ProdutoUnidadeMedida = () => {
    const refProduto = useRef<ProdutoUnidMedProdRef>(null);

    const columns = [
        { field: 'produnidid', header: 'Código' },
        { field: 'produniddescricao', header: 'Descrição' },
        { field: 'produnidativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    function additionalFunctions(row: any) {
        let _result = [];

        _result.push({ click: () => refProduto.current?.openModal(row.produnidid), icon: <FaBoxOpen style={{ fontSize: '24px' }} />, color: COLOR_PRIMARY })

        return _result;
    }

    return (
        <>
            <ConsultaPadrao model={model_produto_unidademedida} columns={columns} sortField="produniddescricao" functions={additionalFunctions} />
            <ProdutoUnidadeMedidaProduto ref={refProduto} />
        </>
    )
}